.bretAnimations {
    animation: opacity 7s;
}

.aboutAnimations {
    animation: opacity 3s, move 3s;
}

@keyframes move {
    0% { transform: translateY(100px); }
    100% { transform: translateY(0px); }
}

@keyframes opacity {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

