@font-face {
    font-family: 'BlinkGaston';
    src: url('../Fonts/BlinkGaston.otf');
    }

@font-face {
    font-family: 'CocogooseLight';
    src: url('../Fonts/CocogooseLight.ttf');
    }

.projects-section {
  /* outline: solid red 2px; */
  min-height: 600px;
}

.projects {
    position: relative;
    margin-left: 15%;
    transform: translate(0, 1vw);
    display: inline-block;
    font-size: 45px;
    font-family: 'CocogooseLight';
    color: #DCDCDC;
    text-shadow: 2px 3px #000000;
    z-index: 1;
}

.slider {
    /* outline: solid yellow 2px; */
    min-height: 32vw;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: nowrap;
  }

  .sliderimage {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
  
  .right-arrow {
    border: solid #DCDCDC;
    border-width: 0 .5vw .5vw 0;
    display: inline-block;
    padding: 1vw;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  
  .left-arrow {
    border: solid #DCDCDC;
    border-width: 0 .5vw .5vw 0;
    display: inline-block;
    padding: 1vw;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }

  .left-arrow:hover {
    border: solid white;
    border-width: 0 .5vw .5vw 0;
    cursor: pointer;
  }

  .right-arrow:hover {
    border: solid white;
    border-width: 0 .5vw .5vw 0;
    cursor: pointer;
  }
  
.slide {
    display: none;
    position: relative;
    transition-duration: 1s;
  }

.slide.active:hover {
    opacity: .6;
}
  
.slide.active {
    transition: opacity 1s;
    display: block;
    position: relative;
    -webkit-animation: fadeIn 1.5s;
    animation: fadeIn 1.5s;
    height: auto;
    width: 60%;
  }

@-webkit-keyframes fadeIn {
    from { opacity: 0; scale: .9;}
      to { opacity: 1; scale: 1;}
}
@keyframes fadeIn {
    from { opacity: 0; scale: .9; }
      to { opacity: 1; scale: 1; }
}

.ball {
    position: absolute;
    font-family: 'CocogooseLight';
    font-size: 15px;
    top: 0;
    left: 0;
    transition: transform 2s linear;
}
