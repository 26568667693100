@font-face {
    font-family: 'BlinkGaston';
    src: url('../Fonts/BlinkGaston.otf');
    }

@font-face {
    font-family: 'CocogooseLight';
    src: url('../Fonts/CocogooseLight.ttf');
    }

.about-container {
    width: 75%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.about-paragraph-container {
    max-width: 500px;
    /* border: solid red 1px; */
    width: auto;
    margin-left: 10px;
}


/* #808080; */
.about-paragraph-text {
    color: #DCDCDC;
    font-size: 10px;
    font-family: 'CocogooseLight';
    text-align:justify
    
}

.about-text-container {
    /* border: solid blue 1px; */
    width: auto;
    margin-right: 10px;
}



.about-large-text {
    font-size:calc(14px + .8vw);
    padding: 5px;
    font-family: 'CocogooseLight';
    color: #DCDCDC;
    align-items: right;
}

h1 {
    text-align: right;
    margin-top: 10px;
    margin-bottom: 10px;
}

.numbers {
    font-family: Arial, Helvetica, sans-serif
}