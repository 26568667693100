nav {
    height: 60px;
    position: fixed;
    top: 0;
    left: 0px;
    right: 0px;
    background-image: linear-gradient(black, 90%, transparent);
    z-index: 10;
}

.header-container {
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    max-height: 50px;
}

.div-logo {
    align: left;
}

.logo {
    width: 50px;
    height: 50px;
}

.div-socials {
    display: flex;
    align-items: center;
}

.socials {
    width: 25px;
    height: 25px;
}
