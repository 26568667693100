@font-face {
    font-family: 'BlinkGaston';
    src: url('../Fonts/BlinkGaston.otf');
    }

@font-face {
    font-family: 'CocogooseLight';
    src: url('../Fonts/CocogooseLight.ttf');
    }

.welcome-container {
    padding-top: 5vh;
    width: 75%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.welcome-text-container {
    width: 50%;
}

.welcome-large-text {
    font-size: 4vw;
    font-family: 'CocogooseLight';
    color: #DCDCDC;
}

h1 {
    margin-top: 0px;
    margin-bottom: 0px;
    line-height: .85;
}

.bret-text {
    margin-top: -40px;
    margin-bottom: -40px;
    font-size: 18vw;
    font-family: 'BlinkGaston';
    color: #89cff0;
    z-index: 1;
}

/* #808080; */
.welcome-small-text {
    margin-left: auto; 
    margin-right: 0;
    width: 100%;
    margin-top: 10%;
    color: #DCDCDC;
    font-size: 1vw;
    font-family: 'CocogooseLight';
}

.welcome-small-text-h3 {
    text-align: right;
}

.welcome-image-container {
    width: 50%;
    z-index:-1;
}

.welcome-image {
    max-width: 100%;
    max-height: 100%;
    pointer-events: none;
    user-select: none;
    mask-image: linear-gradient(black, 80%, transparent);
    filter: grayscale(30%);
}